.users-table-head {
  color: #392e8b;
  font-size: smaller;
}

.users .table > thead > tr > th {
  font-weight: 500;
}

.users .menu-icon {
  cursor: pointer;
}

.users .card-header {
  border-bottom: 1px solid #dee2e6;
  display: inline-block;
  width: 100%;
  padding: 2px 15px 2px 15px;
}

.users .card-title {
  font-weight: 600;
  color: #392e8b;
  float: left;
}
.users .card .card-header .card-title {
  margin-top: 15px;
}

.users .btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #3ebbfc;
  /* float: right; */
}

.users .btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited {
  background-color: #3ebbfc;
  /* float: right; */
}

.react-select .react-select__control {
  border-radius: 4px;
}
