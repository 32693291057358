
.eventsTable td {
    text-align: left;
    padding: 8px;
}

.eventsTable tr:nth-child(even) {
    background-color: #F4F4F4;
}

.eventsTable th {
    padding-left: 16px;
    color: #b2b2b2;
    font-weight: 500
}

.react-select.primary .react-select__control .react-select__value-container--has-value .react-select__single-value,
.react-select.primary .react-select__control .react-select__value-container--has-value .react-select__multi-value__label {
    color: #2c2c2c !important;
    font-size: 12px;
}

input:focus  {
    border-color: #40a9ff !important;
}

.react-select .react-select__control.react-select__control--is-focused,
.react-select .react-select__control.react-select__control--is-focused:hover {
    border-color: #40a9ff !important;
}

.ant-select-selection-placeholder {
    font-size: 14px;
}