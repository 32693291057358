.navbar-date {
  letter-spacing: 0px;
  color: #150c43;
  opacity: 1;
  font-weight: 600;
  font-size: small;
}

.navbar-userInfo {
  color: #392e8b;
  font-size: small;
  font-weight: 600;
  margin: 0 55px;
}

.main-panel {
  background-color: #f5f5f5;
}

.main-panel > .navbar {
  margin-bottom: 60px;
}

@media only screen and (min-width: 1280px) {
  .navbar-date {
    /* margin-left: 100px; */
  }
}

@media screen and (max-width: 991px) {
  .navbar-toggler-bar {
    background: #150c43;
  }
}
