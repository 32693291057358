.email-dashboard-title {
  font-weight: 600;
  font-size: medium;
  color: #7d2eb7;
}
.email-dashboard-subtitle {
}

.dashboard-page .btn-primary {
  padding-left: 50px;
  padding-right: 50px;
  background-color: #392e8b;
}

.dashboard-page .row {
  margin-right: 0px;
  margin-left: 0px;
}

.welcome-dashboard-card {
  background-color: #7d2eb7;
  border-radius: 6px;
}

.welcome-dashboard-title {
  font-weight: 600;
  font-size: medium;
  color: #fff;
}

.welcome-dashboard-subtitle {
  font-size: medium;
  color: #fff;
}

.welcome-dashboard-name {
  padding: 10px 30px;
}

.welcome-dashboard-date {
  font-size: small;
  color: #fff;
  margin-top: 10px;
}

.dashboard-wallet-card-header {
  color: #3c4858 !important;
  font-size: small;
}

.dashboard-wallet-card-main {
  color: #392e8b;
  font-weight: 600;
  font-size: 36px;
}

.dashboard-wallet-card-body {
  color: #392e8b;
  font-weight: 600;
  font-size: x-large;
}

.dashboard-page .card .card-body {
  padding: 0px 15px 10px 15px;
}

.dashboard-page .badge {
  background-color: #e8e8e8;
  border-radius: 8px;
  padding: 4px 8px;
  text-transform: inherit;
  font-size: 0.7142em;
  line-height: 9px;
  border: 1px solid #e8e8e8;
  text-decoration: none;
  color: #3c4858;
  border-radius: 6px;
}

.dashboard-wallet-card-wallet-text {
  font-size: larger;
  color: #3c4858;
  padding: 25px 0 0 20px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #392e8b;
}

.cursor-pointer {
  cursor: pointer;
}
