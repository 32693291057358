@media only screen and (min-width: 1280px) {
  .lock-page .form-group {
    padding-left: 150px;
    padding-right: 150px;
  }
}

.lock-page .input-group,
.form-group {
  margin-bottom: 30px;
}

.lock-page .form-group .form-control,
.input-group .form-control {
  padding: 15px 18px 15px 18px;
}

.lock-page .btn-primary {
  padding-left: 100px;
  padding-right: 100px;
  background-color: #392e8b;
}

.login-button {
  background-color: #392e8b;
  color: #fff;
  border-width: 1px;
  border-radius: 30px;
  padding: 15px 100px;
  cursor: pointer;
  border-width: 1px;
  border-radius: 30px;
  font-size: small;
  font-weight: 600;
}

.cancel-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  opacity: 1;
  color: #999999;
  width: 200px;
  height: 40px;
  padding: 12px 35px;
}

.change-password-button {
  margin-left: 10px;
  width: 200px;
  height: 40px;
  background: #392e8b 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  color: #ffffff;
  padding: 12px 35px;
}

.lock-page .card-title {
  text-align: left;
  font-weight: 600;
  color: #392e8b;
}

.lock-page .card .card-header {
  margin-top: 30px;
}

.lock-page .card .card-footer {
  margin-bottom: 30px;
}

.lock-page .card-lock .card-header img {
  width: 261px;
  height: auto;
  margin-top: 20px;
  border-radius: 0px;
  box-shadow: none;
}

.lock-page .section-image:after {
  background-color: rgba(0, 0, 0, 0);
}

.lock-page .full-page:after {
  background-color: rgba(0, 0, 0, 0);
}

.form-control {
  border-radius: 4px;
}

.back-arrow-auth {
  width: 24px;
  height: 24px;
  margin-top: 35px;
  margin-left: 35px;
  cursor: pointer;
}

.title-tag {
  font-size: 1rem;
  margin-bottom: 5px;
  color: #707070;
}

.two-fac-logos {
  /* width: 93px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; */
  padding: 35px;
  margin-bottom: 10px;
}

.two-fac-border-left {
  cursor: pointer;
  border: 2px solid #e2e2e2;
  margin-left: 100px;
  height: 200px;
  width: 200px;
}

.two-fac-border-right {
  cursor: pointer;
  border: 2px solid #e2e2e2;
  margin-right: 100px;
  height: 200px;
  width: 200px;
}

.success-logo {
  border: 5px solid #392e8b;
  border-radius: 50%;
}

.selected-card-border {
  border: 2px solid #392e8b;
}

@media only screen and (max-width: 1199px) {
  .two-fac-border-left {
    margin-left: 85px;
  }
}

@media only screen and (max-width: 992px) {
  .two-fac-border-left {
    margin: auto;
  }

  .two-fac-border-right {
    margin: 40px auto;
  }
}
.flex{
  display: flex;
  justify-content: space-between;
}