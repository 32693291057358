.sign-out-button {
  margin: 10px 15px 0;
  border-radius: 3px;
  color: #3c4858;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.7142em;
  padding: 10px 8px;
  line-height: 1.625rem;
}

.sign-out-icon {
  font-size: 20px;
  float: left;
  margin-right: 12px;
  line-height: 30px;
  width: 34px;
  text-align: center;
  color: #3c4858;
  position: relative;
}

.sign-out-text {
  margin: 0;
  line-height: 30px;
  position: relative;
  display: block;
  height: auto;
  white-space: nowrap;
  font-weight: 600;
}

.sidebar,
.off-canvas-sidebar {
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 260px;
  left: 0;
  z-index: 9999;
  box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, 0.2),
    0px 2px 30px 0 rgba(0, 0, 0, 0.35);
}
.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
  position: relative;
  height: calc(100vh - 75px);
  overflow: auto;
  width: 260px;
  z-index: 4;
  padding-bottom: 100px;
}
.sidebar .sidebar-wrapper .dropdown .dropdown-backdrop,
.off-canvas-sidebar .sidebar-wrapper .dropdown .dropdown-backdrop {
  display: none !important;
}
.sidebar .sidebar-wrapper .navbar-form,
.off-canvas-sidebar .sidebar-wrapper .navbar-form {
  border: none;
}
.sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  span,
.sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  span,
.off-canvas-sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  span,
.off-canvas-sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  span {
  display: inline-block;
}
.sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.off-canvas-sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal,
.off-canvas-sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-normal {
  margin: 0;
  position: relative;
  transform: translateX(0px);
  opacity: 1;
  white-space: nowrap;
  display: block;
  line-height: 23px;
  z-index: 1;
}
.sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-mini-icon,
.sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-mini-icon,
.off-canvas-sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-mini-icon,
.off-canvas-sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  .sidebar-mini-icon {
  text-transform: uppercase;
  width: 34px;
  margin-right: 10px;
  margin-left: 0px;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
  position: relative;
  float: left;
  z-index: 1;
  display: inherit;
  line-height: 24px;
  color: #3c4858;
}
.sidebar .sidebar-wrapper > .nav [data-toggle="collapse"] ~ div > ul > li > a i,
.sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  i,
.off-canvas-sidebar
  .sidebar-wrapper
  > .nav
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  i,
.off-canvas-sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle="collapse"]
  ~ div
  > ul
  > li
  > a
  i {
  font-size: 17px;
  line-height: 20px;
  width: 26px;
}
.sidebar
  .sidebar-wrapper
  [data-toggle="collapse"]
  ~ div
  > ul
  > li.active
  > a
  .sidebar-mini-icon,
.off-canvas-sidebar
  .sidebar-wrapper
  [data-toggle="collapse"]
  ~ div
  > ul
  > li.active
  > a
  .sidebar-mini-icon {
  color: #ffffff;
}
.sidebar .navbar-minimize,
.off-canvas-sidebar .navbar-minimize {
  position: absolute;
  right: 18px;
  top: 2px;
  opacity: 1;
}
.sidebar .navbar-minimize .btn,
.sidebar .navbar-minimize .btn:active,
.sidebar .navbar-minimize .btn:focus,
.sidebar .navbar-minimize .btn:hover,
.off-canvas-sidebar .navbar-minimize .btn,
.off-canvas-sidebar .navbar-minimize .btn:active,
.off-canvas-sidebar .navbar-minimize .btn:focus,
.off-canvas-sidebar .navbar-minimize .btn:hover {
  background-color: transparent !important;
}
.sidebar .logo-tim,
.off-canvas-sidebar .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden;
}
.sidebar .logo-tim img,
.off-canvas-sidebar .logo-tim img {
  width: 60px;
  height: 60px;
}
.sidebar .nav,
.off-canvas-sidebar .nav {
  margin-top: 20px;
  display: block;
}
.sidebar .nav .caret,
.off-canvas-sidebar .nav .caret {
  top: 14px;
  position: absolute;
  right: 10px;
}
.sidebar .nav li > a + div .nav li > a,
.off-canvas-sidebar .nav li > a + div .nav li > a {
  margin-top: 7px;
}
.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
  margin: 10px 15px 0;
  border-radius: 3px;
  color: #3c4858;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.7142em;
  padding: 10px 8px;
  line-height: 1.625rem;
}
.sidebar .nav li:first-child > a,
.off-canvas-sidebar .nav li:first-child > a {
  margin: 0 15px;
}
.sidebar .nav li:hover:not(.active) > a,
.sidebar .nav li:focus:not(.active) > a,
.off-canvas-sidebar .nav li:hover:not(.active) > a,
.off-canvas-sidebar .nav li:focus:not(.active) > a {
  background-color: rgba(255, 255, 255, 0.1);
}
.sidebar .nav li:hover:not(.active) > a i,
.sidebar .nav li:focus:not(.active) > a i,
.off-canvas-sidebar .nav li:hover:not(.active) > a i,
.off-canvas-sidebar .nav li:focus:not(.active) > a i {
  color: #3c4858;
}
.sidebar .nav li.active > a:not([data-toggle="collapse"]),
.off-canvas-sidebar .nav li.active > a:not([data-toggle="collapse"]) {
  background-color: #392e8b;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
}
.sidebar .nav li.active > a[data-toggle="collapse"],
.off-canvas-sidebar .nav li.active > a[data-toggle="collapse"] {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: none;
  color: #3c4858;
}
.sidebar .nav li.active > a[data-toggle="collapse"] i,
.off-canvas-sidebar .nav li.active > a[data-toggle="collapse"] i {
  color: #3c4858;
}
.sidebar .nav li.active > a[data-toggle="collapse"] + div .nav .active a,
.off-canvas-sidebar
  .nav
  li.active
  > a[data-toggle="collapse"]
  + div
  .nav
  .active
  a {
  background-color: transparent;
  box-shadow: none;
}
.sidebar .nav li.active > a[data-toggle="collapse"] + div .nav .active a:after,
.off-canvas-sidebar
  .nav
  li.active
  > a[data-toggle="collapse"]
  + div
  .nav
  .active
  a:after {
  content: "";
  position: absolute;
  background-color: #392e8b;
  border-radius: 3px;
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
  color: #ffffff;
  height: 44px;
  width: calc(100% - 5px);
  top: 0;
  left: 2px;
  z-index: 0;
}
.sidebar .nav p,
.off-canvas-sidebar .nav p {
  margin: 0;
  line-height: 30px;
  position: relative;
  display: block;
  height: auto;
  white-space: nowrap;
  font-weight: 600;
}
.sidebar .nav i,
.off-canvas-sidebar .nav i {
  font-size: 20px;
  float: left;
  margin-right: 12px;
  line-height: 30px;
  width: 34px;
  text-align: center;
  color: #3c4858;
  position: relative;
}
.sidebar .sidebar-background,
.off-canvas-sidebar .sidebar-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}
.sidebar .sidebar-background:after,
.off-canvas-sidebar .sidebar-background:after {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  background: #ffffff;
  opacity: 1;
}
.sidebar .logo,
.off-canvas-sidebar .logo {
  position: relative;
  padding: 0.5rem 0.7rem;
  z-index: 4;
}
.sidebar .logo a.logo-mini,
.off-canvas-sidebar .logo a.logo-mini {
  opacity: 1;
  float: left;
  width: 34px;
  text-align: center;
  margin-left: 10px;
  margin-right: 12px;
}
.sidebar .logo a.logo-normal,
.off-canvas-sidebar .logo a.logo-normal {
  display: block;
  opacity: 1;
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate3d(0px, 0, 0);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}
.sidebar .logo:after,
.off-canvas-sidebar .logo:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: rgba(255, 255, 255, 0.5);
}
.sidebar .logo p,
.off-canvas-sidebar .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  color: #3c4858;
  line-height: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.sidebar .logo .simple-text,
.off-canvas-sidebar .logo .simple-text {
  text-transform: uppercase;
  padding: 0.5rem 0;
  display: block;
  white-space: nowrap;
  font-size: 1em;
  color: #3c4858;
  text-decoration: none;
  font-weight: 400;
  line-height: 30px;
  overflow: hidden;
}
.sidebar .logo-tim,
.off-canvas-sidebar .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden;
}
.sidebar .logo-tim img,
.off-canvas-sidebar .logo-tim img {
  width: 60px;
  height: 60px;
}
.sidebar:before,
.sidebar:after,
.off-canvas-sidebar:before,
.off-canvas-sidebar:after {
  display: block;
  content: "";
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.sidebar:after,
.off-canvas-sidebar:after {
  background: #888;
  background: -webkit-linear-gradient(#888 0%, #000 80%);
  background: -o-linear-gradient(#888 0%, #000 80%);
  background: -moz-linear-gradient(#888 0%, #000 80%);
  background: linear-gradient(#888 0%, #000 80%);
  z-index: 3;
}
.sidebar[data-color="blue"]:after,
.off-canvas-sidebar[data-color="blue"]:after {
  background: #ffffff;
}
.sidebar[data-color="blue"] .nav li.active > a:not([data-toggle="collapse"]),
.off-canvas-sidebar[data-color="blue"]
  .nav
  li.active
  > a:not([data-toggle="collapse"]) {
  color: #ffffff;
}
.sidebar[data-color="blue"] .nav li.active > a:not([data-toggle="collapse"]) i,
.off-canvas-sidebar[data-color="blue"]
  .nav
  li.active
  > a:not([data-toggle="collapse"])
  i {
  color: #ffffff;
}

.sidebar .user,
.off-canvas-sidebar .user {
  padding-bottom: 20px;
  margin: 20px auto 0;
  position: relative;
}
.sidebar .user:after,
.off-canvas-sidebar .user:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 15px;
  height: 1px;
  width: calc(100% - 30px);
  background-color: rgba(255, 255, 255, 0.5);
}
.sidebar .user .photo,
.off-canvas-sidebar .user .photo {
  width: 34px;
  height: 34px;
  overflow: hidden;
  float: left;
  z-index: 5;
  margin-right: 10px;
  border-radius: 50%;
  margin-left: 23px;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}
.sidebar .user .photo img,
.off-canvas-sidebar .user .photo img {
  width: 100%;
}
.sidebar .user a,
.off-canvas-sidebar .user a {
  color: #3c4858;
  text-decoration: none;
  padding: 0.5rem 15px;
  white-space: nowrap;
}
.sidebar .user .info > a,
.off-canvas-sidebar .user .info > a {
  display: block;
  line-height: 18px;
}
.sidebar .user .info > a > span,
.off-canvas-sidebar .user .info > a > span {
  display: block;
  position: relative;
  opacity: 1;
}
.sidebar .user .info .caret,
.off-canvas-sidebar .user .info .caret {
  position: absolute;
  top: 8px;
  right: 18px;
}

.visible-on-sidebar-regular {
  display: inline-block !important;
}

.visible-on-sidebar-mini {
  display: none !important;
}

.off-canvas-sidebar .nav > li > a,
.off-canvas-sidebar .nav > li > a:hover {
  color: #3c4858;
}

.off-canvas-sidebar .nav > li > a:focus {
  background: rgba(200, 200, 200, 0.2);
}

.sidebar .logo,
.off-canvas-sidebar .logo {
  padding: 2.5rem 1.7rem;
}

.sidebar .nav li.active > a[data-toggle="collapse"],
.sidebar .nav li > a[aria-expanded="true"],
.off-canvas-sidebar .nav li.active > a[data-toggle="collapse"],
.off-canvas-sidebar .nav li > a[aria-expanded="true"],
.sidebar .nav li.active ~ li a[aria-expanded="true"] i,
.sidebar .nav li.active ~ li a[aria-expanded="true"] i,
.off-canvas-sidebar .nav li.active ~ li a[aria-expanded="true"] i,
.off-canvas-sidebar .nav li.active ~ li a[aria-expanded="true"] i {
  color: #3c4858;
}
